import { AssetsPipe } from './assets.pipe';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BankImgPipe } from './bank-img.pipe';
import { RetailerImgPipe } from './retailer-img.pipe';
import { FilterBrandOffersPipe } from './filter-brand-offers.pipe';
import { FilterOfferCategoryPipe } from './filter-offer-category.pipe';
import { LoyaltyFilterPipe } from './loyalty-filter.pipe';
import { FilterOfferPartnerPipe } from './filter-offer-partner.pipe';
import { IbanPipe } from './iban.pipe';



@NgModule({
  declarations: [AssetsPipe, BankImgPipe, RetailerImgPipe, FilterBrandOffersPipe, FilterOfferCategoryPipe, LoyaltyFilterPipe, FilterOfferPartnerPipe, IbanPipe ],
  imports: [CommonModule],
  exports: [AssetsPipe, BankImgPipe, RetailerImgPipe, FilterBrandOffersPipe, FilterOfferCategoryPipe, LoyaltyFilterPipe, FilterOfferPartnerPipe, IbanPipe]
})
export class PipesModule {
}
