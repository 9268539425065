import { Pipe, PipeTransform } from '@angular/core';
import { Offer } from '../types/offer.type';

@Pipe({
  name: 'filterOfferPartner'
})
export class FilterOfferPartnerPipe implements PipeTransform {

  transform(offerList: Offer[] | null, search: string[] | null, type: string | null): Offer[] | null {
    if (!offerList) return null
    else if (type === 'include') return offerList.filter(offer => this.isInclude(offer, search) )
    else if (type === 'exclude') return offerList.filter(offer => !this.isInclude(offer, search) )
    else return offerList.filter(offer => this.isInclude(offer, search) )
  }

  isInclude(offer: Offer, search: string[] | null): boolean {
    if (offer.partner && search)
      return search.includes(offer.partner)
    return false
  }

}
