<footer role="contentinfo" class="food-footer no-print">
    <div class="food-footer-container">
      <section class="food-footer-u-align-center">
        <svg aria-label="Logo de 750 grammes" class="food-footer-u-icon food-footer-u-icon-logo-bubble" role="img" viewBox="0 0 687.57 687.57">
          <path d="M621.18 112.39C562.87 39.48 470.2 1.67 345.74 0h-1.86C229.92 0 142.7 30.12 84.64 89.53 28.94 146.52.47 231.83 0 343.07S27.3 539.75 82.5 597c57.78 59.88 145.2 90.36 259.83 90.6h1.39c113.94 0 201.15-30.11 259.2-89.51 55.7-57 84.18-142.3 84.65-253.55.43-97.65-21.9-175.71-66.39-232.15z" fill="#08be8d"></path>
          <path fill="#fff" d="M155.85 427.14H118.7l39.78-149.79H92.35v-35.91h112.82l-49.32 185.7zM227 379.42a31.12 31.12 0 10-1-25.5l-34-11.47 27.76-101h89.1v35.91h-61.75L240.85 300a68.09 68.09 0 0114-1.46 67 67 0 11-64 87zM453.31 267.44c-12.07-21.27-31.16-32.26-56.75-32.67h-.14c-23.57 0-41.59 8.88-53.58 26.39-11.41 16.67-16.95 40.4-16.95 72.53s5.54 55.86 16.95 72.54c12 17.51 30 26.39 53.58 26.39S438 423.7 450 406.19c11.41-16.68 17-40.4 17-72.54-.08-27.78-4.66-50.06-13.69-66.21zm-23.48 66.21a191.18 191.18 0 01-1.56 24.08v.14c-3.81 34.12-20 38.51-32 38.8-11.64 0-19.42-4.23-24.46-13.22-5.88-10.45-8.81-27.25-8.81-49.8s3-39.29 8.87-49.79c5.06-9 12.86-13.23 24.57-13.23s19.51 4.2 24.57 13.23c5.83 10.5 8.82 27.26 8.82 49.79zM564.11 300.37v7.28c-8.62-6-19.09-9-31.15-9-18.79 0-34.1 6.3-45.53 18.73-11 12-16.52 27-16.52 44.76s5.56 32.79 16.52 44.75c11.42 12.43 26.33 18.73 44.31 18.73 12.59 0 23.45-3.12 32.32-9.29-.34 8.3-2.53 14.57-6.52 18.66-4.32 4.43-11.95 6.67-22.7 6.67-20.23 0-24.73-9.24-25.88-14.47l-32.12 5.44a51.18 51.18 0 0020.81 28.47c9.86 6.66 23.31 10.12 40.07 10.12 17.15 0 31.13-4.85 41.54-14.42 10.59-9.73 16-23.78 16-41.76V300.37zm-7.26 37.29C562.33 344 565 352 565 362.09s-2.66 18-8.11 24.37a30.21 30.21 0 01-9.77 7A31.77 31.77 0 01534 396c-9.89 0-17.54-3.13-23.37-9.54S502 372 502 362.09s2.79-17.88 8.54-24.37 13.49-9.56 23.38-9.56c9.61 0 17.08 3.12 22.93 9.5z"></path>
        </svg>
        <span class="food-footer-separator" role="separator"> Un site du Groupe</span>
        <a href="https://fr.webedia-group.com/" target="_blank">
          <svg role="img" class="food-footer-u-icon food-footer-u-icon-webedia" viewBox="0 0 894.17 400">
            <circle cx="200" cy="200" r="200" fill="#0028bd"></circle>
            <path d="m57.14 141.44 35.3 114.05h35.3l-35.3-114.05h-35.3zM127.74 141.44l35.3 114.05h35.3l-35.3-114.05h-35.3z" fill="#b0c0ea"></path>
            <path d="m163.04 141.44-35.3 114.05h-35.3l35.3-114.05h35.3zM233.65 141.44l-35.31 114.05h-35.3l35.3-114.05h35.31zM304.25 141.44l-35.3 114.05h-35.3l35.3-114.05h35.3z" fill="#fff"></path>
            <path d="m304.25 141.44-35.3 114.05h-35.3l35.3-114.05h35.3zM323.89 258.56a18.89 18.89 0 0 1-13.95-5.73 19.1 19.1 0 0 1-5.69-14 18 18 0 0 1 5.69-13.24 19.1 19.1 0 0 1 13.95-5.59 19.11 19.11 0 0 1 19 18.85 19.36 19.36 0 0 1-5.58 14 18 18 0 0 1-13.42 5.71Z" fill="#fff"></path>
            <path d="M461.39 168.87H473v17.93h-11.61v44.33h-18.67V186.8h-8.35v-17.93h8.35c0-17.43 9.58-27.89 30.25-26.64v17.93c-6.97-.62-11.58 1.49-11.58 8.71Z" fill="#001478"></path>
            <path d="M471.73 200a33 33 0 1 1 33 32.88 32.63 32.63 0 0 1-33-32.88Zm47.27 0c0-8.84-6.22-14.69-14.32-14.69S490.4 191.16 490.4 200s6.23 14.69 14.32 14.69S519 208.84 519 200Z" fill="#001478"></path>
            <path d="M542.08 200a33 33 0 1 1 33 32.88 32.63 32.63 0 0 1-33-32.88Zm47.31 0c0-8.84-6.22-14.69-14.32-14.69s-14.32 5.85-14.32 14.69 6.23 14.69 14.32 14.69 14.32-5.85 14.32-14.69Z" fill="#001478"></path>
            <path d="M679.42 144v87.16h-18.68v-5.85c-4.11 4.73-10.21 7.6-18.55 7.6-16.32 0-29.76-14.33-29.76-32.88s13.44-32.87 29.76-32.87c8.34 0 14.44 2.86 18.55 7.59V144Zm-18.68 56c0-9.34-6.23-15.19-14.82-15.19S631.1 190.66 631.1 200s6.23 15.19 14.82 15.19 14.82-5.85 14.82-15.19Z" fill="#001478"></path>
            <path d="M498.5 292.52v38.22h-18.68v-35.48c0-7.72-4.85-11.33-10.83-11.33-6.85 0-11.58 4-11.58 12.82v34h-18.68v-62.26h18.68v5.85c3.36-4.61 9.59-7.6 17.8-7.6 12.58 0 23.29 8.97 23.29 25.78ZM539.84 315.8c5.1 0 9.46-2 11.95-4.73l14.94 8.59c-6.1 8.47-15.31 12.83-27.14 12.83-21.3 0-34.49-14.32-34.49-32.88s13.44-32.87 33.12-32.87c18.18 0 31.62 14.07 31.62 32.87a36.15 36.15 0 0 1-.74 7.48h-44.21c2.37 6.47 8.11 8.71 14.95 8.71Zm11.45-22.66c-2-7.22-7.59-9.84-13.2-9.84-7.09 0-11.83 3.49-13.57 9.84ZM599.1 286.42v22c0 5.35 4.61 5.85 12.83 5.35v16.93c-24.4 2.49-31.5-4.85-31.5-22.28v-22h-10v-17.93h10v-11.83l18.67-5.61v17.44h12.83v17.93ZM709.05 268.49l-19.92 62.25h-17.44l-10-33.24-10 33.24h-17.35l-19.92-62.25h19.92l9 33.37 9.66-33.37h17.43l9.71 33.37 9-33.37ZM707.8 299.61a33 33 0 1 1 33 32.88 32.64 32.64 0 0 1-33-32.88Zm47.32 0c0-8.84-6.23-14.69-14.32-14.69s-14.32 5.85-14.32 14.69 6.23 14.7 14.32 14.7 14.32-5.85 14.32-14.7ZM818.62 267.24v21.17c-7.72-1.25-18.68 1.87-18.68 14.19v28.14h-18.67v-62.25h18.67v11.08c2.49-8.34 10.84-12.33 18.68-12.33ZM862.82 330.74l-19.92-27.51v27.51h-18.68v-87.16h18.68v52.17l18.68-27.26h21.79l-22.66 31.12L884 330.74Z" fill="#b0c0ea"></path>
            <path d="M894.17 69.39v62.26h-18.68v-5.85c-4.11 4.73-10.21 7.6-18.55 7.6-16.32 0-29.76-14.32-29.76-32.88s13.44-32.87 29.76-32.87c8.34 0 14.44 2.86 18.55 7.59v-5.85Zm-18.68 31.13c0-9.34-6.23-15.19-14.82-15.19s-14.82 5.85-14.82 15.19 6.23 15.19 14.82 15.19 14.82-5.85 14.82-15.19ZM560.39 116.71c5.11 0 9.47-2 12-4.73l14.94 8.59C581.19 129 572 133.4 560.14 133.4c-21.29 0-34.49-14.32-34.49-32.88s13.45-32.87 33.12-32.87c18.18 0 31.63 14.07 31.63 32.87a36 36 0 0 1-.75 7.47h-44.2c2.37 6.48 8.09 8.72 14.94 8.72Zm11.46-22.66c-2-7.23-7.6-9.84-13.2-9.84-7.1 0-11.83 3.49-13.57 9.84ZM661.69 100.52c0 18.56-13.45 32.88-29.76 32.88-8.34 0-14.45-2.87-18.55-7.6v5.85H594.7V44.49h18.68v30.75c4.1-4.73 10.21-7.59 18.55-7.59 16.31 0 29.76 14.35 29.76 32.87Zm-18.68 0c0-9.34-6.23-15.19-14.82-15.19s-14.81 5.85-14.81 15.19 6.22 15.19 14.81 15.19S643 109.86 643 100.52ZM698.58 116.71c5.1 0 9.46-2 11.95-4.73l14.94 8.59c-6.1 8.47-15.31 12.83-27.14 12.83-21.29 0-34.49-14.32-34.49-32.88S677.29 67.65 697 67.65c18.18 0 31.63 14.07 31.63 32.87a36 36 0 0 1-.75 7.47h-44.2c2.32 6.48 8.05 8.72 14.9 8.72ZM710 94.05c-2-7.23-7.59-9.84-13.19-9.84-7.1 0-11.83 3.49-13.58 9.84ZM797.73 44.49v87.16h-18.68v-5.85c-4.11 4.73-10.21 7.6-18.55 7.6-16.32 0-29.76-14.32-29.76-32.88s13.44-32.87 29.76-32.87c8.34 0 14.44 2.86 18.55 7.59V44.49Zm-18.68 56c0-9.34-6.23-15.19-14.82-15.19s-14.82 5.85-14.82 15.19 6.23 15.19 14.82 15.19 14.82-5.82 14.82-15.16ZM804.19 69.39h18.68v62.26h-18.68ZM509.58 69.26l-9.64 31.13-9.63-31.13h-19.27l-9.64 31.13-9.64-31.13h-19.27l19.27 62.26h19.28l9.63-31.13 9.64 31.13h19.27l19.27-62.26h-19.27zM813.7 63.91a9.8 9.8 0 0 1-9.88-9.91 9 9 0 0 1 2.86-6.66 9.62 9.62 0 0 1 7-2.82 9.61 9.61 0 0 1 9.56 9.48 9.73 9.73 0 0 1-2.81 7 9.06 9.06 0 0 1-6.73 2.91Z" fill="#0028be"></path>
          </svg>
        </a>
        <div class="food-footer-u-clear"></div>
        <ul class="food-footer-social">
          <li class="food-footer-u-iblock">
            <a rel="noopener" target="_blank" href="https://www.pinterest.com/750grammes" class="food-footer-social-button food-footer-social-pinterest" tabindex="0">
              <svg role="img" class="food-footer-u-icon food-footer-u-icon-social" viewBox="0 0 18 18" width="18" height="18">
                <title lang="fr">Icone Pinterest</title>
                <path d="M9.4,0C4.5,0,2,3.5,2,6.5c0,1.8,0.7,3.4,2.1,3.9c0.2,0.1,0.4,0,0.5-0.3c0-0.2,0.2-0.6,0.2-0.8c0.1-0.3,0-0.4-0.1-0.6C4.3,8.3,4,7.6,4,6.7c0-2.6,2-5,5.1-5c2.8,0,4.3,1.7,4.3,4c0,3-1.3,5.5-3.3,5.5c-1.1,0-1.9-0.9-1.6-2c0.3-1.3,0.9-2.7,0.9-3.7C9.4,4.7,9,4,8,4C6.9,4,6,5.2,6,6.7c0,1,0.3,1.6,0.3,1.6S5.2,13.1,5,14c-0.4,1.7-0.1,3.7,0,3.9C5,18,5.2,18,5.2,17.9c0.1-0.1,1.4-1.8,1.9-3.4c0.1-0.5,0.7-2.8,0.7-2.8c0.4,0.7,1.4,1.3,2.5,1.3c3.3,0,5.6-3,5.6-7.1C15.9,2.9,13.3,0,9.4,0z"></path>
              </svg>
            </a>
          </li>
          <li class="food-footer-u-iblock">
            <a rel="noopener" target="_blank" href="https://www.facebook.com/lapagede750grammes" class="food-footer-social-button food-footer-social-facebook" tabindex="0">
              <svg role="img" class="food-footer-u-icon food-footer-u-icon-social" viewBox="0 0 18 18" width="18" height="18">
                <title lang="fr">Icone facebook</title>
                <path d="M6.9,5.9H5V9h1.9v9h3.6V8.9H13l0.3-3h-2.8c0,0,0-1.1,0-1.7c0-0.7,0.1-1,0.8-1c0.6,0,2,0,2,0V0c0,0-2.1,0-2.5,0C8.1,0,6.9,1.2,6.9,3.5C6.9,5.4,6.9,5.9,6.9,5.9z"></path>
              </svg>
            </a>
          </li>
          <li class="food-footer-u-iblock">
            <a rel="noopener" target="_blank" href="https://www.instagram.com/750grammes" class="food-footer-social-button food-footer-social-instagram" tabindex="0">
              <svg role="img" class="food-footer-u-icon food-footer-u-icon-social" viewBox="0 0 18 18" width="18" height="18">
                <title lang="fr">Icone Instagram</title>
                <path d="M15.9,7.6h-1.6c0.1,0.4,0.2,0.9,0.2,1.4c0,3.1-2.5,5.5-5.5,5.5c-3.1,0-5.5-2.5-5.5-5.5 c0-0.5,0.1-0.9,0.2-1.4H2.1v7.6c0,0.4,0.3,0.7,0.7,0.7h12.5c0.4,0,0.7-0.3,0.7-0.7L15.9,7.6L15.9,7.6z M15.9,2.8c0-0.4-0.3-0.7-0.7-0.7h-2.1c-0.4,0-0.7,0.3-0.7,0.7v2.1c0,0.4,0.3,0.7,0.7,0.7h2.1c0.4,0,0.7-0.3,0.7-0.7V2.8z M9,5.5C7.1,5.5,5.5,7.1,5.5,9c0,1.9,1.5,3.5,3.5,3.5c1.9,0,3.5-1.6,3.5-3.5C12.5,7.1,10.9,5.5,9,5.5 M15.9,18H2.1C0.9,18,0,17.1,0,15.9V2.1C0,0.9,0.9,0,2.1,0h13.8C17.1,0,18,0.9,18,2.1v13.8C18,17.1,17.1,18,15.9,18"></path>
              </svg>
            </a>
          </li>
          <li class="food-footer-u-iblock">
            <a rel="noopener" target="_blank" href="https://www.youtube.com/user/750Grammes" class="food-footer-social-button food-footer-social-youtube" tabindex="0">
              <svg role="img" class="food-footer-u-icon food-footer-u-icon-social" viewBox="0 0 18 18" width="18" height="18">
                <title>Icone Youtube</title>
                <path d="M3.3,0h1.1l0.7,2.8L5.8,0h1.1L5.6,4.1v2.8H4.6V4.1L3.3,0z M6.8,5.7C6.8,6.6,7.3,7,8.2,7C9,7,9.6,6.5,9.6,5.7V3.2c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4V5.7z M7.8,3.2c0-0.3,0.1-0.5,0.4-0.5c0.3,0,0.4,0.2,0.4,0.5v2.4c0,0.3-0.1,0.5-0.4,0.5C8,6.1,7.8,5.9,7.8,5.6V3.2z M12.2,1.8v3.9c-0.1,0.1-0.4,0.4-0.6,0.4c-0.2,0-0.3-0.1-0.3-0.3V1.8h-1v4.3c0,0.5,0.2,0.9,0.7,0.9c0.3,0,0.7-0.2,1.1-0.6v0.6h1V1.8H12.2z M10.8,12.3c0.1,0.1,0.1,0.2,0.1,0.4v2.5c0,0.2,0,0.3-0.1,0.3c-0.1,0.1-0.3,0.1-0.5,0.1c-0.1,0-0.1-0.1-0.2-0.2v-3c0.1-0.1,0.1-0.1,0.2-0.1C10.5,12.2,10.7,12.2,10.8,12.3z M13.8,12.2c-0.3,0-0.4,0.2-0.4,0.6v0.5h0.8v-0.5C14.2,12.5,14.2,12.2,13.8,12.2z M16.6,15.8c0,1.2-1,2.2-2.2,2.2H3.2C2,18,1,17,1,15.8v-5.5c0-1.2,1-2.2,2.2-2.2h11.2c1.2,0,2.2,1,2.2,2.2L16.6,15.8L16.6,15.8z M4.7,10.7h1.1v-1H2.6v1h1.1v5.7h1V10.7L4.7,10.7z M8.3,11.5H7.4v3.7c-0.1,0.1-0.4,0.4-0.5,0.4c-0.2,0-0.2-0.1-0.2-0.3v-3.7H5.7v4.1c0,1,0.7,1,1.2,0.7c0.2-0.1,0.4-0.3,0.5-0.5v0.5h0.9V11.5zM11.8,12.6c0-0.7-0.2-1.2-0.9-1.2c-0.3,0-0.6,0.2-0.8,0.5V9.7H9.2v6.6h0.9V16c0.3,0.3,0.5,0.4,0.9,0.4c0.6,0,0.8-0.5,0.8-1.1V12.6zM15.2,12.8c0-0.9-0.4-1.4-1.3-1.4c-0.8,0-1.4,0.6-1.4,1.4V15c0,0.9,0.4,1.5,1.3,1.5c1,0,1.4-0.6,1.4-1.5v-0.4h-0.9V15c0,0.4,0,0.7-0.4,0.7c-0.4,0-0.4-0.3-0.4-0.7V14h1.7L15.2,12.8L15.2,12.8z"></path>
              </svg>
            </a>
          </li>
          <li class="food-footer-u-iblock">
            <a rel="noopener" target="_blank" href="https://www.twitter.com/750Grammes" class="food-footer-social-button food-footer-social-twitter" tabindex="0">
              <svg role="img" class="food-footer-u-icon food-footer-u-icon-social" viewBox="0 0 18 18" width="18" height="18">
                <title lang="fr">Icone Twitter</title>
                <path d="M18,3.7c-0.7,0.3-1.4,0.5-2.1,0.6c0.8-0.5,1.3-1.2,1.6-2C16.8,2.7,16,3,15.2,3.2C14.5,2.4,13.5,2,12.5,2c-2.4,0-4.1,2.2-3.6,4.5C5.8,6.4,3.1,4.9,1.3,2.7c-1,1.7-0.5,3.8,1.1,4.9c-0.6,0-1.2-0.2-1.7-0.5c0,1.7,1.2,3.3,3,3.7C3.2,11,2.6,11,2,10.9c0.5,1.5,1.8,2.5,3.4,2.6C3.9,14.7,2,15.2,0,15c1.6,1,3.6,1.7,5.7,1.7c6.9,0,10.7-5.8,10.5-11C16.9,5.1,17.5,4.5,18,3.7z"></path>
              </svg>
            </a>
          </li>
        </ul>
        <ul class="food-footer-u-padding-top food-footer-u-margin-top food-footer-u-margin-bottom">
          <li class="food-footer-u-iblock food-footer-u-margin-right-10px">
            <a href="http://www.750g.docker/qui_sommes-nous_.htm" class="food-footer-u-link food-footer-u-upper food-footer-u-rem-085" tabindex="0">À propos</a>
          </li>
          <li class="food-footer-u-iblock food-footer-u-margin-right-10px">
            <a href="http://www.750g.docker/contactez-nous.htm" class="food-footer-u-link food-footer-u-upper food-footer-u-rem-085" tabindex="0">Contact</a>
          </li>
          <li class="food-footer-u-iblock food-footer-u-margin-right-10px">
            <a href="http://www.750g.docker/newsletter-inscription.htm" class="food-footer-u-link food-footer-u-upper food-footer-u-rem-085" tabindex="0">Newsletter</a>
          </li>
          <li class="food-footer-u-iblock food-footer-u-margin-right-10px">
            <a href="https://www.750g.com/notifications-push-750g" class="food-footer-u-link food-footer-u-upper food-footer-u-rem-085" tabindex="0">Notifications</a>
          </li>
          <li class="food-footer-u-iblock food-footer-u-margin-right-10px">
            <a href="http://www.750g.docker/publicite.htm" class="food-footer-u-link food-footer-u-upper food-footer-u-rem-085" tabindex="0">Commercial</a>
          </li>
          <li class="food-footer-u-iblock food-footer-u-margin-right-10px">
            <a href="https://fr.webedia-group.com/nous-rejoindre/1" class="food-footer-u-link food-footer-u-upper food-footer-u-rem-085" tabindex="0">Recrutement</a>
          </li>
          <li class="food-footer-u-iblock food-footer-u-margin-right-10px">
            <a href="http://www.750g.docker/mentions_legales.htm" class="food-footer-u-link food-footer-u-upper food-footer-u-rem-085" tabindex="0">Mentions légales</a>
          </li>
          <li class="food-footer-u-iblock food-footer-u-margin-right-10px">
            <a href="http://www.750g.docker/cgu.htm" class="food-footer-u-link food-footer-u-upper food-footer-u-rem-085" tabindex="0">CGU</a>
          </li>
          <li class="food-footer-u-iblock food-footer-u-margin-right-10px">
            <a href="http://www.750g.docker/cpu.htm" class="food-footer-u-link food-footer-u-upper food-footer-u-rem-085" tabindex="0">CPU</a>
          </li>
          <li class="food-footer-u-iblock food-footer-u-margin-right-10px">
            <a href="http://www.750g.docker/cgv-offre-payante-site-web.htm" class="food-footer-u-link food-footer-u-upper food-footer-u-rem-085" tabindex="0">CGV de l'offre payante 750g.com</a>
          </li>
          <li class="food-footer-u-iblock food-footer-u-margin-right-10px">
            <a href="http://www.750g.docker/cgv.htm" class="food-footer-u-link food-footer-u-upper food-footer-u-rem-085" tabindex="0">CGV du service 750g Premium sur les applications mobiles</a>
          </li>
          <li class="food-footer-u-iblock food-footer-u-margin-right-10px">
            <a href="http://www.750g.docker/charte_de_confidentialite.htm" class="food-footer-u-link food-footer-u-upper food-footer-u-rem-085" tabindex="0">Protection des données personnelles</a>
          </li>
          <li class="food-footer-u-iblock food-footer-u-margin-right-10px">
            <a href="http://www.750g.docker/cookies.htm" class="food-footer-u-link food-footer-u-upper food-footer-u-rem-085" tabindex="0">Cookies</a>
          </li>
            <li class="food-footer-u-iblock food-footer-u-margin-right-10px">
            <a href="javascript:Didomi.notice.show()" class="food-footer-u-link food-footer-u-upper food-footer-u-rem-085" tabindex="0">Préférences cookies</a>
          </li>
        </ul>
      </section>
    </div>
  </footer>
  