import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PotDetail } from 'src/shared/types/pot.type';
import { EnvironmentService } from '../environment/environment.service';
import { Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PotRestService {
  private readonly routes = {
    potDetail: `${this.environment.APIBaseUrl}/pot/detail`,
    preferredRecipient: `${this.environment.APIBaseUrl}/pot/preferred-recipient`,
    potPayment: `${this.environment.APIBaseUrl}/pot/payment`
  }


  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService
  ) { }

  getPotDetail(): Observable<PotDetail> {
    return this.httpClient.get<PotDetail>(this.routes.potDetail).pipe(take(1));
  }

  // POST : preferred recipient
  public postPreferredRecipient(IBAN : string): Observable<Response> {
    return this.httpClient.post<Response>(this.routes.preferredRecipient, {"iban":IBAN});
  }

  // GET : payement (recover cashback)
  getPotPayment(): Observable<PotDetail> {
    return this.httpClient.get<PotDetail>(this.routes.potPayment);
  }


}
