import { Pipe, PipeTransform } from '@angular/core';
import { BankImgService } from '../services/bank-img.service';

@Pipe({
  name: 'bankImg'
})
export class BankImgPipe implements PipeTransform {

  constructor(
    private bankImgService: BankImgService) {

  }
  /**
  * return the root of the bank image from the bank name
  * @param value string
  * @param args any
  * @returns transformed path (./assets/bank-icons/xxx)
  */
 transform(value: string, ...args: any[]): any {
  const bankIco = this.bankImgService.getImgPath(value);
  const path = './assets/bank-icons/' + bankIco;
  return path;
}

}
