import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  keyLanguage = 'userLanguage';
  private _userLanguage = '';
  private supportedLanguages =  ['fr'] //['en', 'fr']

  /**
   * @constructor
   * @param translate 
   */
  constructor(
    private translate: TranslateService,
  ) { 
    this.initLanguage(); 
    this.translate.use(this._userLanguage)
  }

  /**
  * set the application language to the default browser language
  */
  initLanguage() {
    const value = localStorage.getItem(this.keyLanguage);
    if (value != null) {
      this._userLanguage = value;
    } else {
      const browserLanguage = navigator.language.split('-')[0]; //fr-FR
      this._userLanguage = 'fr'; // default
      if (this.supportedLanguages.includes(browserLanguage)) {
        this._userLanguage = browserLanguage;
        localStorage.setItem(this.keyLanguage, browserLanguage)
      }
    }
  }

  /**
   * If we use a toggle button to change the language
   * @param language la langue du bouton à bascule
   */
  setLanguage(language: string) {
    this._userLanguage = language;
    localStorage.setItem(this.keyLanguage, this._userLanguage);
    this.translate.use(this._userLanguage);
  }

  /**
   * get the user language
   */
  get userLanguage() {
    return this._userLanguage;
  }

  /**
   * @returns supported languages
   */
  getAvailableLanguages() {
    return this.supportedLanguages;
  }
}
