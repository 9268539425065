import { Component, OnInit } from '@angular/core';
import { LanguageService } from './core/services/language/language.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AuthService } from './auth/services/auth.service';
import { UtmService } from './core/services/utm.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  isLogged!: boolean;

  constructor(
    private languageService: LanguageService,
    private utmService: UtmService,
    ){}

}
