import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-lesnum',
  templateUrl: './footer-lesnum.component.html',
  styleUrls: ['./footer-lesnum.component.scss']
})
export class FooterLesnumComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
