import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assets'
})
export class AssetsPipe implements PipeTransform {

  /**
 * Assets Pipe
 * @param value assets value
 * @param args
 * @returns ./assets/value
 */
  transform(value: unknown, ...args: unknown[]): unknown {
    return "./assets/" + value;
  }

}
