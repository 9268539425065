import { NewUserModel, UserToken, UserProfile, CguPartner, PostResponse, SponsorshipCode } from '../../../shared/types/user.type';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment.preprod';
import { EnvironmentService } from '../environment/environment.service';
import { FormGroup } from '@angular/forms';
import { Transaction } from 'src/shared/types/reclamation.type';
import { ResponseStatus, RequestUtils } from 'src/shared/utils/request.utils';

@Injectable({
  providedIn: 'root'
})

export class UserRestService {
  private readonly routes = {
    createUser: `${this.environment.APIBaseUrl}/user/create`,
    deleteUser: `${this.environment.APIBaseUrl}/user/delete`,
    userInformation: `${this.environment.APIBaseUrl}/user/profile`,
    acceptCGU: `${this.environment.APIBaseUrl}/user/cgu-partner`,
    newsletter: `${this.environment.APIBaseUrl}/user/newsletter`,
    newsletter_unsubscribe: `${this.environment.APIBaseUrl}/user/newsletter_unsubscribe`,
    sponsorshipCode: `${this.environment.APIBaseUrl}/user/sponsorship-code`
  }



  constructor(
    private environment: EnvironmentService,
    private httpClient: HttpClient
  ) { }


  /**
   * Create User
   * @param newUserItem user defined by the interface: NewUserModel
   * @returns Observable user defined by User token
   */
  public createUser(newUserItem: NewUserModel): Observable<UserToken> {
    return this.httpClient.post<UserToken>(this.routes.createUser, newUserItem);
  }

  public createNewUser(newUserItem: NewUserModel): Observable<boolean> {
    return this.httpClient.post<ResponseStatus>(this.routes.createUser, newUserItem)
    .pipe(RequestUtils.isStatusOK());
  }

  public deleteUser(): Observable<Response> {
    return this.httpClient.delete<Response>(this.routes.deleteUser);
  }

  /**
   * Get user information
   * @returns Observable user information
   */
  public getUserInformation(): Observable<UserProfile> {
    return this.httpClient.get<UserProfile>(this.routes.userInformation);
  }

  /**
  * Update user information
  * @returns Observable user information
  * have to check this function
  */
  public updateUserInformation(updatedUser: UserProfile): Observable<UserProfile> {  // utiliser cette fonction en l'état !!
      return this.httpClient.put<UserProfile>(this.routes.userInformation, updatedUser);      //return this.httpClient.get<UserProfile>(this.routes.userInformation);
  }

  public acceptCGU(cguPartner : CguPartner) : Observable<any> {
    return this.httpClient.post(this.routes.acceptCGU, cguPartner);
  }

  public updateNewsletter(newsletter: boolean) : Observable<any> {
    const requestParams = {
      newsletter: newsletter
    };
    return this.httpClient.post(this.routes.newsletter, requestParams)
  }

  public unsubscribeNewsletter(email: string, token: string) : Observable<any> {
    const requestParams = {
      email: email,
      token: token
    };
    return this.httpClient.post(this.routes.newsletter_unsubscribe, requestParams)
  }

  /**
   * Return user status in function of his email
   * @param userEmail
   * @returns user status
   */
  public getUserStatus(userEmail: string): Observable<any> {
    const requestParams = {
      email: userEmail
    };
    return this.httpClient.post(`${this.environment.APIBaseUrl}/user/check-user`, requestParams);
  }

  public getSponsorshipCode(): Observable<SponsorshipCode> {
    return this.httpClient.get<SponsorshipCode>(this.routes.sponsorshipCode)
  }

}
