import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iban'
})
export class IbanPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    // adding spaces each 4 characters
    return value.replace(/(.{4})/g, '$1 ').replace(/(^\s+|\s+$)/,'');
  }

}
