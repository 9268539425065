import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UtmService implements OnDestroy{

  private readonly utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
  private subscriptions: Subscription[] =[]

  constructor(private route: ActivatedRoute) {
    this.init();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => { s.unsubscribe()})
  }

  init() {
    const sub = this.route.queryParams
      .subscribe(params => {
        let href = params['href']
        if (href && this.isUtm(href))
          sessionStorage.setItem('href', href)
      });
    this.subscriptions.push(sub)
  }

  getUtm() {
    let href = sessionStorage.getItem('href')
    if (href)
      return href!;
    return undefined;
  }

  isUtm(href: String) {
    return this.utmParams.some(param => href.includes(param));
  }
}
