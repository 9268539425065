import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export class RequestUtils {
  static isStatusOK(): OperatorFunction<ResponseStatus, boolean> {
    return map((response: ResponseStatus) => response.status === 'OK');
  }

  static throwIfNotOk(): OperatorFunction<ResponseStatus, boolean> {
    return map((response: ResponseStatus) => {
      const isOk = response.status === 'OK';
      if (!isOk) {
        throw new Error('Expected ok got not ok');
      }
      return true;
    });
  }
}

export interface ResponseStatus {
  status: string;
}
