
import { NgModule } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthService } from './auth/services/auth.service';

export function tokenGetter() {
    return localStorage.getItem(AuthService.accessToken);
}

export const _allowedDomains = [
    new RegExp('^null$'), //// workaround https://github.com/auth0/angular2-jwt/issues/504
    '192.168.1.199:8080',
    '192.168.1.201:8080',
    '10.0.2.2:8080',
    '127.0.0.1:8080',
    '35.190.204.124:83', // preprod abble
    'preprod.euxene.com',
    'www.aufeminin.com',
    'aufeminin.com',
    'www.goldcircle.io',
    'goldcircle.io',
    'abble.io',
    'app.goldcircle.io',
    'gold-circle.euxene.com',
    'preprod.gold.goldcircle.io',
    'www.preprod.gold.goldcircle.io',
    'preprod.goldcircle.io',
    'api.goldcircle.io',
];

export const _disallowedRoutes = [
    new RegExp('^null$'), //// workaround https://github.com/auth0/angular2-jwt/issues/504
];

@NgModule({
    // help : https://www.c-sharpcorner.com/article/jwt-token-authentication-in-angular-14-and-net-core-6-web-api/
    imports: [JwtModule.forRoot({
        config: {
            headerName: 'X-Auth-Token',
            authScheme: '', // The default authorization scheme is Bearer,
            tokenGetter: tokenGetter,
            allowedDomains: _allowedDomains,
            // If you do not want to replace the authorization headers for specific route
            disallowedRoutes: _disallowedRoutes
        }
    })],
    exports: [JwtModule]
})
export class AppTokenModule { }
