import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-750g',
  templateUrl: './header-750g.component.html',
  styleUrls: ['./header-750g.component.scss']
})
export class Header750gComponent implements OnInit {


  constructor() {}

  ngOnInit(): void {}

}

