import { Injectable } from '@angular/core';
import { PotRestService } from '../services/pot-rest.service';
import { BehaviorSubject } from 'rxjs';
import { action } from 'mobx-angular';
import { runInAction } from 'mobx';
import { PotDetail } from 'src/shared/types/pot.type';
import { DomainStore } from 'src/app/core/state-manager/abstract-store';

@Injectable({providedIn: 'root'})
export class PotStoreService extends DomainStore {

  public isPotDetailLoading$: BehaviorSubject<any> = new BehaviorSubject(false);
  public potDetail$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private potRestService: PotRestService
  ) {
    super();
    this.fetchPotDetail();
  }

  init() {
    this.fetchPotDetail();
  }


  @action
  fetchPotDetail() {
    this.isPotDetailLoading$.next(true);
   return this.potRestService.getPotDetail()
    .subscribe(potDetail => {
      runInAction(() => {
        //console.log(potDetail)
        this.potDetail$.next(potDetail);
        this.isPotDetailLoading$.next(false);
      });
    });
    //this.subscriptions.push(potSubscription);
  }


  clean(): void {
    this.potDetail$.next(undefined);
  }
}
