import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'src/shared/pipe/pipes.module';
import { ErrorComponent } from './error/error.component';
import { FemmeactuelleHeaderComponent } from './femmeactuelle-header/femmeactuelle-header.component';
import { Footer750gComponent } from './footer-750g/footer-750g.component';
import { FooterLesnumComponent } from './footer-lesnum/footer-lesnum.component';
import { FooterComponent } from './footer/footer.component';
import { Header750gComponent } from './header-750g/header-750g.component';
import { HeaderLesnumComponent } from './header-lesnum/header-lesnum.component';
import { HeaderComponent } from './header/header.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
  declarations: [
    ErrorComponent,
    FooterComponent,
    HeaderComponent,
    TopMenuComponent,
    FemmeactuelleHeaderComponent,
    HeaderLesnumComponent,
    FooterLesnumComponent,
    Header750gComponent,
    Footer750gComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    PipesModule,
    RouterModule,
    TranslateModule,
  ],
  exports: [
    ErrorComponent,
    FooterComponent,
    HeaderComponent,
    TopMenuComponent,
    FemmeactuelleHeaderComponent,
    HeaderLesnumComponent,
    FooterLesnumComponent,
    Header750gComponent,
    Footer750gComponent
  ]
})
export class LayoutsModule { }
