import { Pipe, PipeTransform } from '@angular/core';
import { CampaignDetailItem } from '../types/campaign.type'

@Pipe({
  name: 'filterOfferCategory',
  pure: false
})
export class FilterOfferCategoryPipe implements PipeTransform {

  transform(campaignList: CampaignDetailItem[] | null, search: number | null): CampaignDetailItem[] | null {
    if ((!search && search != 0) || search === -1) {
      return campaignList
    }
    if (campaignList) {
      return campaignList!.filter(campaignList => campaignList.campaignType === search)
    } else {
      return null
    }
  }

}
