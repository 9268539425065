import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-unavailable',
  templateUrl: './popup-unavailable.component.html',
  styleUrls: ['./popup-unavailable.component.scss']
})
export class PopupUnavailableComponent implements OnInit {
  title: string;
  description: string;
  
  constructor(
    public dialogRef: MatDialogRef<PopupUnavailableComponent>,
    @Inject (MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.description = data.description;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.closePopup()
    }, 10000);
  }

  closePopup() {
    this.dialogRef.close();
  }
}
