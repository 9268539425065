import {
  UserToken,
  qiotaRequest,
  tokenQiota,
} from '../../../shared/types/user.type';
import { Observable, map, take, catchError } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthRestService } from './auth-rest.service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  static readonly accessToken = 'abble_access_token';
  static readonly accessUser = 'abble_access_user';

  /**
   * @constructor
   * @param jwthelperService Help to decode the token
   * @param authRestService Authentication Rest Service
   */
  constructor(
    private jwthelperService: JwtHelperService,
    private authRestService: AuthRestService,
    private router: Router
  ) { }

  /**
   * Add token to local storage
   * @param response user token
   */
  public tokenSetter(response: UserToken) {
    localStorage.setItem(AuthService.accessToken, response.token);
    localStorage.setItem(AuthService.accessUser, JSON.stringify(response));
  }

  /**
   * Remove token from local storage
   */
  public tokenRemover() {
    localStorage.removeItem(AuthService.accessToken);
    localStorage.removeItem(AuthService.accessUser);
  }

  /**
   * Connect the user
   * @param email user email
   * @param password user password
   * @returns user token
   */
  public login(email: string, password: string): Observable<UserToken> {
    return this.authRestService.login(email, password).pipe(
      take(1),
      map((response: UserToken) => {
        this.tokenSetter(response);
        return response;
      })
    );
  }

  public logout(): void {
    const params = {
      clearHistory: true,
    };
    if (this.jwthelperService.tokenGetter() != null) {
      this.authRestService.logout().toPromise();
      this.tokenRemover();
      this.router.navigate(['/login'], params as any);
    }
  }

  public logoutPrisma(): void {
    const params = {
      clearHistory: true,
    };
    if (this.jwthelperService.tokenGetter() != null) {
      this.authRestService.logout().toPromise();
      this.tokenRemover();
    }
  }

  /**
   * isTokenExpired return false if no expiration date present in token
   * @returns return ! this.jwtHelper.isTokenExpired()
   */
  public isLoggedIn(): boolean {
    return !!this.jwthelperService.tokenGetter();
  }

  getToken() {
    return this.jwthelperService.tokenGetter();
  }

  getTokenInformation() {
    const token: string = this.jwthelperService.tokenGetter().toString();
    if (token) {
      try {
        return this.jwthelperService.decodeToken(token);
      } catch {
        return {};
      }
    }
    return {};
  }

  getQiotaToken(code: string): Observable<tokenQiota> {
    return this.authRestService.horyzonSSOCheck(code);
  }
}
