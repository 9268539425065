import { Injectable } from '@angular/core';
import { BankImg } from '../types/bank.type';

@Injectable({
  providedIn: 'root'
})
export class BankImgService {
  private bankImg: Map<string, BankImg>;

  /**
   * @constructor
   * Mapping Bank icons
   */
  constructor() {
    this.bankImg = new Map();
    this.bankImg.set('Allianz Banque', {
      name: 'Allianz Banque',
      imgPath: 'logo_bank_Allianz.png'
    });
    this.bankImg.set('American Express', {
      name: 'American Express',
      imgPath: 'logo_bank_AE.png'
    });
    this.bankImg.set('Attijarinet', {
      name: 'Attijarinet',
      imgPath: 'logo_bank_Attijarinet.png'
    });
    this.bankImg.set('Attijariwafa', {
      name: 'Attijariwafa',
      imgPath: 'logo_bank_Attijariwafa.png'
    });
    this.bankImg.set('AXA Banque', {
      name: 'Axa Banque',
      imgPath: 'logo_bank_AXA.png'
    });
    this.bankImg.set('Bank of Africa', {
      name: 'Bank of Africa',
      imgPath: 'logo_bank-BOA.png'
    });
    this.bankImg.set('Banque Accord', {
      name: 'Banque Accord',
      imgPath: 'logo_bank_accor.png'
    });
    this.bankImg.set('Banque Bami', {
      name: 'Banque Bami',
      imgPath: 'logo_bank_bami.png'
    });
    this.bankImg.set('Banque BCP', {
      name: 'Banque BCP',
      imgPath: 'logo_bank_Bcp.png'
    });
    this.bankImg.set('Banque Chaix', {
      name: 'Banque Chaix',
      imgPath: 'logo_bank_Banquechaix.png'
    });
    this.bankImg.set('Banque Courtois', {
      name: 'Banque Courtois',
      imgPath: 'logo_bank_banqueCourtois.png'
    });
    this.bankImg.set('Banque de la Réunion', {
      name: 'Banque de la Réunion',
      imgPath: 'logo_bank_BanqueREUNIONVERSION2.png'
    });
    this.bankImg.set('Banque de Nouvelle-Calédonie', {
      name: 'Banque de Nouvelle-Calédonie',
      imgPath: 'logo_bank-NC.png'
    });
    this.bankImg.set('Banque Edmond de Rothschild', {
      name: 'Banque Edmon de Rothschild',
      imgPath: 'logo_bank_EDRVERSION2.png'
    });
    this.bankImg.set('Banque Française Commerciale Océan Indien', {
      name: 'Banque Française Commerciale Océan Indien',
      imgPath: 'logo_bank_BFC.png'
    });
    this.bankImg.set('Banque Populaire', {
      name: 'Banque Populaire',
      imgPath: 'logo_bank_banque_populaire.png'
    });
    this.bankImg.set('Banque Populaire Maroc', {
      name: 'Banque Populaire Maroc',
      imgPath: 'logo_bank_BPMaroc.png'
    });
    this.bankImg.set('Banque Pouyanne', {
      name: 'Banque Pouyanne',
      imgPath: 'logo_bank_Bpoyanne.png'
    });
    this.bankImg.set('Banque Rhône-Alpes', {
      name: 'Banque Rhône-Alpes',
      imgPath: 'logo_bank_RhonesAlpes.png'
    });
    this.bankImg.set('Banque SBE', {
      name: 'Banque SBE',
      imgPath: 'logo_bank_BSBE.png'
    });
    this.bankImg.set('Banque Transatlantique', {
      name: 'Banque Transatlantique',
      imgPath: 'logo_bank-BT.png'
    });
    this.bankImg.set('BBVA', {
      name: 'BBVA',
      imgPath: 'logo_bank_BBVA.png'
    });
    this.bankImg.set('BforBank', {
      name: 'BforBank',
      imgPath: 'logo_bank-BFB.png'
    });
    this.bankImg.set('BNP Paribas', {
      name: 'BNP Paribas',
      imgPath: 'logo_bank_BNPP.png'
    });
    this.bankImg.set('BNP Paribas Antilles', {
      name: 'BNP Paribas Antilles',
      imgPath: 'logo_bank_BNPP Antilles.png'
    });
    this.bankImg.set('BNP Paribas Guyane', {
      name: 'BNP Paribas Guyane',
      imgPath: 'logo_bank_BNPP.png'
    });
    this.bankImg.set('BNP Paribas Nouvelle-Calédonie', {
      name: 'BNP Paribas Nouvelle-Calédonie',
      imgPath: 'logo_bank_BNPP Nouvelle-caledonie.png'
    });
    this.bankImg.set('BNP Paribas Réunion', {
      name: 'BNP Paribas Réunion',
      imgPath: 'logo_bank_BNPP Reunion .png'
    });
    this.bankImg.set('Boursorama', {
      name: 'Boursorama',
      imgPath: 'logo_bank_boursorama.png'
    });
    this.bankImg.set('Cadrol', {
      name: 'Cadrol',
      imgPath: 'logo_bank_Cadrol.png'
    });
    this.bankImg.set("Caisse d'Épargne Particuliers", {
      name: "Caisse d'Epargne Particuliers",
      imgPath: 'logo_bank_CE.png'
    });
    this.bankImg.set('Carrefour Banque', {
      name: 'Carrefour Banque',
      imgPath: 'logo_bank_CARREFOURVERSION2.png'
    });
    this.bankImg.set('CIC', {
      name: 'CIC',
      imgPath: 'logo_bank_CIC.png'
    });
    this.bankImg.set('Citibank', {
      name: 'Citibank',
      imgPath: 'logo_bank_citybank.png'
    });
    this.bankImg.set('Compte Nickel', {
      name: 'Compte Nickel',
      imgPath: 'logo_bank-Nickel.png'
    });
    this.bankImg.set('Crédit Agricole', {
      name: 'Crédit Agricole',
      imgPath: 'logo_bank_CA.png'
    });
    this.bankImg.set('Crédit Coopératif', {
      name: 'Crédit Coopératif',
      imgPath: 'logo_bank-CreditCoo.png'
    });
    this.bankImg.set('Crédit du Nord', {
      name: 'Crédit du Nord',
      imgPath: 'logo_bank_creditN.png'
    });
    this.bankImg.set('Crédit Maritime', {
      name: 'Crédit Maritime',
      imgPath: 'logo_bank_BM.png'
    });
    this.bankImg.set('Crédit Mutuel', {
      name: 'Crédit  Mutuel',
      imgPath: 'logo_bank_CM.png'
    });
    this.bankImg.set('Crédit Mutuel de Bretagne', {
      name: 'Crédit Mutuel de Bretagne',
      imgPath: 'logo_bank_CM.png'   // imgPath: 'logo_bank_CM Bretagne.png'
    });
    this.bankImg.set('Crédit Mutuel du Sud Ouest', {
      name: 'Crédit Mutuel du Sud Ouest',
      imgPath: 'logo_bank_CM.png'      // imgPath: 'logo_bank_CM SOpng.png'
    });
    this.bankImg.set('Delubac', {
      name: 'Delubac',
      imgPath: 'logo_bank_delubac.png'
    });
    this.bankImg.set('Eko by Crédit Agricole', {
      name: 'Eko by Crédit Agricole',
      imgPath: 'logo_bank_eko.png'
    });
    this.bankImg.set('Floa Bank', {
      name: 'Floa Bank',
      imgPath: 'logo_bank_FB.png'
    });
    this.bankImg.set('Fortuneo', {
      name: 'Fortuneo',
      imgPath: 'logo_bank_fortuneo.png'
    });
    this.bankImg.set('Hello bank!', {
      name: 'Hello Bank!',
      imgPath: 'logo_bank_HB.png'
    });
    this.bankImg.set('HSBC France', {
      name: 'HSBC France',
      imgPath: 'logo_bank_HSBC.png'
    });
    this.bankImg.set('ING France', {
      name: 'ING France',
      imgPath: 'logo_bank_ING.png'
    });
    this.bankImg.set('Kiwibank', {
      name: 'Kiwibank',
      imgPath: 'logo_bank_kiwi.png'
    });
    this.bankImg.set('La Banque Postale', {
      name: 'La Banque Postale',
      imgPath: 'logo_bank_banque_postale.png'
    });
    this.bankImg.set('LCL', {
      name: 'LCL',
      imgPath: 'logo_bank_LCL.png'
    });
    this.bankImg.set('Lydia', {
      name: 'Lydia',
      imgPath: 'logo_bank_lydia.png'
    });
    this.bankImg.set('Macif Espace Banque', {
      name: 'Macif Espace Banque',
      imgPath: 'logo_bank_macif.png'
    });
    this.bankImg.set('Monabanq', {
      name: 'Monabanq',
      imgPath: 'logo_bank_monabank.png'
    });
    this.bankImg.set('Moneo Resto', {
      name: 'Moneo resto',
      imgPath: 'logo_bank-Moneoresto.png'
    });
    this.bankImg.set('Monte Paschi Banque', {
      name: 'Monte Paschi Banque',
      imgPath: 'logo_bank_BMP.png'
    });
    this.bankImg.set('Mooncard', {
      name: 'Mooncard',
      imgPath: 'logo_bank_MC.png'
    });
    this.bankImg.set('N26', {
      name: 'N26',
      imgPath: 'logo_bank_N26.png'
    });
    this.bankImg.set('Oney', {
      name: 'Oney',
      imgPath: 'logo_bank_onney.png'
    });
    this.bankImg.set('Orange Bank', {
      name: 'Orange Bank',
      imgPath: 'logo_bank_OB.png'
    });
    this.bankImg.set('Palatine', {
      name: 'Palatine',
      imgPath: 'logo_bank_palatine.png'
    });
    this.bankImg.set('Paypal', {
      name: 'Paypal',
      imgPath: 'logo_bank_Paypal.png'
    });
    this.bankImg.set('Revolut', {
      name: 'Revolut',
      imgPath: 'logo_bank_revolut.png'
    });
    this.bankImg.set('Rothschild Martin Maurel', {
      name: 'Rothschild Martin Maurel',
      imgPath: 'logo_bank_MARTINMAURELVERSION2.png'
    });
    this.bankImg.set('Société Générale', {
      name: 'Société Générale',
      imgPath: 'logo_bank_SG.png'
    });
    this.bankImg.set('Société Générale Calédonienne de Banque', {
      name: 'Société Générale Calédonienne de Banque',
      imgPath: 'logo_bank_SG caledonienne de banque.png'
    });
    this.bankImg.set('Société Marseillaise de Crédit', {
      name: 'Société Marseillaise de Crédit',
      imgPath: 'logo_bank_SMCpng.png'
    });
    this.bankImg.set('Sodexo', {
      name: 'Sodexo',
      imgPath: 'logo_bank_sodexopng.png'
    });
    this.bankImg.set('Swile', {
      name: 'Swile',
      imgPath: 'logo_bank_swile.png'
    });
    this.bankImg.set('Connecteur de test', {
      name: 'Connecteur de test',
      imgPath: 'no_found.png'
    });
  }

  getImgPath(bankName: string): string {
    const bankItem = this.bankImg.get(bankName);
    return bankItem ? bankItem.imgPath : 'no_found.png';
  }
}
