import { UserStoreService } from '../state-manager/user-store.service';

import { Injectable } from '@angular/core';
import { runInAction } from 'mobx';
import { BehaviorSubject, Observable, catchError, empty, map, take, tap, throwError } from 'rxjs';
import { UserRestService } from './user-rest.service';
import { NewUserModel, UserProfile, UserToken, CguPartner, PostResponse, SponsorshipCode } from '../../../shared/types/user.type';
import { AuthService } from 'src/app/auth/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthRestService } from '../../auth/services/auth-rest.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userTokenSubject: BehaviorSubject<UserToken> = new BehaviorSubject({} as UserToken);
  public mustRefresh$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public userProfileCompletion$: BehaviorSubject<number> = new BehaviorSubject(0)

  /**
   * @constructor
   * @param userStore UserStoreService
   */
  constructor(
    private userStore: UserStoreService,
    private userRestService: UserRestService,
    private authService: AuthService,
    private authRestService: AuthRestService
  ) {
    // this.getUserTokenData().subscribe(
    //   token => this.updateUserToken(token),
    //   err => {
    //     console.error(err);
    //     this.authService.logout();
    //   }
    // );
  }

  /**
   * Set the user's token from the user's store and change the value of _user.token
   * @param userToken user token from login API
   */
  public setUserToken(userToken: UserToken) {
    runInAction(() => this.userStore.updateUserToken(userToken));
    this.userTokenSubject.next(userToken);
  }


  public createUser(newUserItem: NewUserModel): Observable<UserToken> {
    return this.userRestService.createUser(newUserItem).pipe(
      take(1), tap(token => {
        return this.setUserToken(token)
      })
    )
  }

  public createNewUser(newUserItem: NewUserModel): Observable<boolean> {
    return this.userRestService.createNewUser(newUserItem);
  }

  /**
   *
   * @returns
   */
  private getUserTokenData(): Observable<UserToken> {
    const infos = this.authService.getTokenInformation();
    if (!infos.jti) {
      return empty();
    }
    return this.authRestService
      .token(infos.jti, this.authService.getToken())
      .pipe(catchError((err: any) => this.handleError(err)!));
  }

  /**
   * Updated user's token
   * @param userToken Actual token
   */
  private updateUserToken(userToken: UserToken) {
    if (userToken.id) {
      this.setUserToken(userToken);
    }
  }

  private handleError(error: HttpErrorResponse | any) {
    let errMsg: string;
    console.log(error);
    if (error.status === 400 || error.status !== 200) {
      // Fatal error
      return throwError('Fatal error, token invalid');
    } else {
      const body = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    }
    return null;
    console.error(errMsg);
  }

/**
 * Get user data
 * @returns user data
 */
  public getUserInformation() : Observable<UserProfile>{
    return this.userRestService.getUserInformation().pipe(map(userProfile => {
      this.userProfileCompletion$.next(userProfile.profilePercent!)
      return userProfile
    }));
  }

  /**
   * Update user data
   * @param updatedUser user data updated
   * @returns ko or success
   */
  public updateUserInformation(updatedUser : UserProfile) : Observable<UserProfile>{
    return this.userRestService.updateUserInformation(updatedUser);
  }

  public acceptCGU(cguPartner: CguPartner) : Observable<PostResponse> {
    return this.userRestService.acceptCGU(cguPartner);
  }

  public updateNewsletter(newsletter: boolean) {
    return this.userRestService.updateNewsletter(newsletter)
  }

  public unsubscribeNewsletter(email: string, token: string) {
    return this.userRestService.unsubscribeNewsletter(email, token)
  }

  /**
   * Get sponsorship code
   * @returns sponsorship code
   */
  public getSponsorshipCode() : Observable<SponsorshipCode> {
    return this.userRestService.getSponsorshipCode();
  }
}
