import { AuthGuard } from './auth/guard/auth.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { PreloadAllModules } from '@angular/router';
import { WebAppComponent } from './web-app/web-app.component';


export const GLOBAL_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'offerWeb',
    loadChildren: () => import('./offer-web/offer-web.module')
      .then(m => m.OfferWebModule)
  },
  {
    path: 'newsletterUnsubscribe',
    loadChildren: () => import('./standalone-page/unsubscribe-newsletter/unsubscribe-newsletter.module')
      .then(m => m.UnsubscribeNewsletterModule)
  },
  {
    path: '',
    component: WebAppComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./public/public.module')
          .then(m => m.PublicModule)
      },
      {
        path: '',
        loadChildren: () => import('./auth/auth.module')
          .then(m => m.AuthModule)
      },
      {
        path: 'home',
        loadChildren: () => import('./home/home.module')
          .then(m => m.HomeModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'offers',
        loadChildren: () => import('./brand/brand.module')
          .then(m => m.BrandModule),
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(GLOBAL_ROUTES, { scrollPositionRestoration: 'enabled',  preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
