<!-- header -->
<!-- <app-top-menu></app-top-menu> -->

<!--<div>
  <embed
  type="text/html"
  src="https://life.mensup.fr/gc-header.html"
  width="100%"
  title="header men-life"
  style="top: 0; z-index: 100; position: fixed; max-height: 2rem"
/>
</div>-->

<!-- <div class="header-top navbar navbar-expand-lg">
  <app-top-menu></app-top-menu>
</div> -->

<!-- header end -->

<nav class="navbar navbar-expand-lg p-20 d-flex flex-column">
  <!-- header men life -->

  <div class="header-top">
    <app-top-menu></app-top-menu>
  </div>

  <!-- header men life end-->

  <!-- header lesnums -->

  <!-- <div class="header-top">
    <app-header-lesnum></app-header-lesnum>
  </div> -->

  <!-- header lesnums end-->

  <!-- header 750g-->
  
  <!-- <div class="header-top">
    <app-header-750g></app-header-750g>
  </div> -->

  <!-- header 750g end -->

  <div class="container-fluid">
    <a routerLink="home/dashboard" class="navbar-brand">
      <!-- menlife -->
      <img [src]="'img/horizon/LogoCashBack-Blanc.png' | assets" alt="brand logo" />
      <!-- femmeactuelle -->
      <!-- <img [src]="'img/prisma/femme_actuelle/brand_logo.svg' | assets" alt="brand logo" /> -->
      <!-- les numerique -->
      <!-- <img [src]="'img/lesnumeriques/logo-lesnum-gold_V3.png' | assets" alt="brand logo" /> -->
      <!-- 750g -->
      <!-- <img [src]="'img/750g/club750g.svg' | assets" alt="brand logo" /> -->
    </a>
    <!-- Toggler mobile -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll"
      aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- End toggler -->

    <div class="collapse navbar-collapse nav-items-container" id="navbarScroll">
      <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
        <!-- <li
          [routerLink]="['/home/dashboard']"
          [routerLinkActive]="'active'"
          class="nav-item nav-link"
          aria-current="page"
        >
          {{ "navbar.spotlight" | translate }}
        </li> -->
        <li [routerLink]="['/home/shopOffer']" [routerLinkActive]="'active'" class="nav-item nav-link" data-bs-toggle="collapse"
          data-bs-target="#navbarScroll">
          {{ "navbar.shop-offer" | translate }}
        </li>
        <li [routerLink]="['/home/internetOffer']" [routerLinkActive]="'active'" class="nav-item nav-link" data-bs-toggle="collapse"
          data-bs-target="#navbarScroll">
          {{ "navbar.internet-offer" | translate }}
        </li>
        <li [routerLink]="['/offers']" [routerLinkActive]="'active'" class="nav-item nav-link" data-bs-toggle="collapse"
          data-bs-target="#navbarScroll">
          {{ "navbar.offers" | translate }}
        </li>

        <!-- <li [routerLink]="['home/faq']" [routerLinkActive]="'active'" class="nav-item nav-link"
          data-bs-toggle="collapse" data-bs-target="#navbarScroll">
          {{ "navbar.faq" | translate }}
        </li> -->
        <li [routerLink]="['home/user-profile']" [routerLinkActive]="'active'"
          class="nav-item nav-link nav-profil-mobile" data-bs-toggle="collapse" data-bs-target="#navbarScroll">
          {{ "navbar.profile" | translate }}
        </li>
        <!-- <li
          *ngIf="isLogged"
          [routerLink]="['/logout']"
          [routerLinkActive]="'active'"
          class="nav-item nav-link"
        >
          {{ "navbar.logout" | translate }}
        </li> -->

        <!-- <li routerLink="home/user-profile" routerLinkActive="active" class="nav-item nav-link">
                    <a [routerLink]="['/home/user-profile']" routerLinkActive="router-link-active">{{"navbar.profile" | translate}}</a>
                </li> -->
      </ul>
      <!-- <form class="d-flex" role="search">
                <input class="form-control me-2" type="search" placeholder='{{ " navbar.search " | translate}}' aria-label="Search">
            </form> -->
      <!-- <p
        class="otherPot"
        *ngIf="potDetail && potDetail.totalAmount !== 0; else emptyPot"
      >
        {{ potDetail.totalAmount }}€
      </p>
      <ng-template #emptyPot>
        <p *ngIf="isLogged" class="otherPot">-€</p>
      </ng-template> -->

      <div class="nav-profil-web">
        <svg *ngIf="isLogged" xmlns="http://www.w3.org/2000/svg" routerLink="home/user-profile"
          routerLinkActive="active" width="16" height="16" fill="currentColor" class="bi bi-person-circle profilePic"
          data-bs-toggle="collapse" data-bs-target="#navbarScroll" viewBox="0 0 16 16" data-bs-toggle="collapse"
          data-bs-target="#navbarScroll">
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
          <path fill-rule="evenodd"
            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
        </svg>

        <!-- User Data Visible In Header -->
        <ul class="userData" routerLink="home/user-profile" routerLinkActive="active" data-bs-toggle="collapse"
          data-bs-target="#navbarScroll" *ngIf="userProfile">
          <li>
            <p *ngIf="potDetail" class="name">
              {{ userProfile.firstName }}
            </p>
          </li>
          <li>
            <p *ngIf="potDetail && potDetail.totalAmount !== 0; else emptyPot" class="money">
              {{ potDetail.totalAmount }} €
            </p>
          </li>
        </ul>
        <ng-template #emptyPot>
          <p class="otherPot">0 €</p>
        </ng-template>
      </div>
    </div>
  </div>
</nav>

<script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
<script>
  // Function to check the screen width and collapse the navbar if it's below a certain threshold
  function checkScreenWidth() {
    console.log("test")
    if ($(window).width() < 992) {
      // Collapse the navbar for screen widths below 992px
      $(".navbar-collapse").collapse("hide");
    }
  }

  // Check the screen width on page load and on window resize
  $(document).ready(checkScreenWidth);
  $(window).resize(checkScreenWidth);
</script>


<!-- OLD -->
<!--
  * Defined scroll height
  style="--bs-scroll-height: 100px" -->

<!-- DROPDOWN BTS HELP -->
<!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Link
              </a>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item">Action</a></li>
                        <li><a class="dropdown-item">Another action</a></li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item">Something else here</a></li>
                    </ul>
                </li>
               -->