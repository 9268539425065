import { Injectable, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

@Injectable()
export abstract class ComponentWithSubscriptions implements OnDestroy {
    protected subscriptions: Subscription[] = [];

    /**
     * Close all user's subscriptions
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }
}