import { Component, OnInit, OnDestroy } from '@angular/core';

import { AuthService } from '../../auth/services/auth.service';
import { PotStoreService } from '../../core/state-manager/pot-store.service';
import { PotDetail } from '../../../shared/types/pot.type';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { UserProfile } from 'src/shared/types/user.type';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { PopupUnavailableComponent } from 'src/app/popup/popup-unavailable/popup-unavailable.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  potDetail!: PotDetail;
  userProfile!: UserProfile;
  isLogged!: boolean;
  isProd!: boolean;
  private subscriptions: Subscription[] = []

  constructor(
    public dialog : MatDialog,
    private authService: AuthService,
    private potStore: PotStoreService,
    private userService: UserService
  ) {
    // this.displayHeader =
  }

  ngOnInit(): void {
    this.isProd = environment.production
    // this.userService.mustRefresh$.next(false);

    const subPotStore = this.potStore.potDetail$.subscribe(
      (potDetail: PotDetail) => {
        this.potDetail = potDetail;
      }
    );
    this.subscriptions.push(subPotStore);

    this.isLogged = this.authService.isLoggedIn();

    const subUser = this.userService.getUserInformation().subscribe(
      (values : UserProfile) => {
        this.userProfile = values
      }
    )
    this.subscriptions.push(subUser);

    // refresh if logging in
    const subUserLogged = this.userService.mustRefresh$.subscribe(
      status => {
        if (status) {
          this.refresh()
          this.userService.mustRefresh$.next(false)
        }
      }
    )
    this.subscriptions.push(subUserLogged)
  }

  refresh() : void {
    // window.location.reload()
    this.potStore.init()
    this.isLogged = true;
    // retry to subscribe when logged in
    const subUser = this.userService.getUserInformation().subscribe(
      (values : UserProfile) => {this.userProfile = values}
    )
    this.subscriptions.push(subUser);
    const subPotStore = this.potStore.potDetail$.subscribe(
      (potDetail: PotDetail) => {this.potDetail = potDetail;}
    );
    this.subscriptions.push(subPotStore);
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  openMenlife() {
    // window.open('https://life.mensup.fr/', '_blank')
    console.log("ok")
  }

  //When Shop Page is unavailable on prod
  openUnavailableShop() {
    if (this.isLogged) {
      this.dialog.open(PopupUnavailableComponent, {
        autoFocus: false,
        maxWidth: '1000px',
        width: '80%',
        maxHeight: '80vh',
        data : {
          title: 'popin.unavailable.shop.title',
          description: 'popin.unavailable.shop.description'
        }
      });
    }
  }
}
