import { Pipe, PipeTransform } from '@angular/core';
import { BrandList } from 'src/shared/types/offer.type';

@Pipe({
  name: 'filterBrandOffers'
})
export class FilterBrandOffersPipe implements PipeTransform {

  transform(brandList: BrandList[] | null , search : string): BrandList[] | null {
    if (brandList) return brandList.filter(brandList => brandList.brandName.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
    else return null
  }

}
