import { ComponentWithSubscriptions } from 'src/shared/helpers/componentWithSubscriptions';
import { RootStoreService } from './root-store.service';
import { configure } from 'mobx';


export abstract class DomainStore extends ComponentWithSubscriptions {
  // will clean all state (ex user switch)
  // todo : implement without triggering reaction
  abstract clean(): void;

  protected _rootStoreService!: RootStoreService;

  protected constructor() {
    super();
    // can't alter store if no threw an action
    configure({
      enforceActions: 'observed'
    });
  }

  setRootStoreService(rootStoreService: RootStoreService) {
      this._rootStoreService = rootStoreService;
  }

}
