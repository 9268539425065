import { Injectable } from '@angular/core';
import { RetailerImg } from './../types/campaign.type';

@Injectable({
  providedIn: 'root'
})
export class RetailerImgService {
  private retailerImg: Map<string, RetailerImg>;

  constructor() {
    this.retailerImg = new Map();
    this.retailerImg.set('Carrefour', {
      name: 'Carrefour',
      imgPath: 'carrefour.png'
    });
    this.retailerImg.set('Auchan', {
      name: 'Auchan',
      imgPath: 'auchan.png'
    });
    this.retailerImg.set('Système U', {
      name: 'Système U',
      imgPath: 'systeme_u.png'
    });
    this.retailerImg.set('Monoprix Click&Collect', {
      name: 'Monoprix Click&Collect',
      imgPath: 'monoprix_click&collect.png'
    });
    this.retailerImg.set('E.Leclerc Drive', {
      name: 'E.Leclerc Drive',
      imgPath: 'leclerc_drive.png'
    });
    this.retailerImg.set('E.Leclerc', {
      name: 'E.Leclerc',
      imgPath: 'leclerc_drive.png'
    });
    this.retailerImg.set('Intermarché', {
      name: 'Intermarché',
      imgPath: 'intermarche_drive.png'
    });
    // casino * 2
    this.retailerImg.set('Casino Drive', {
      name: 'Casino Drive',
      imgPath: 'casino_drive.png'
    });
    this.retailerImg.set('Casino Max', {
      name: 'Casino Max',
      imgPath: 'casino_max.png'
    });
    this.retailerImg.set('Lidl', {
      name: 'Lidl',
      imgPath: 'Lidl.png'
    });
    //GSS
    this.retailerImg.set('Marionnaud', {
      name: 'Marionnaud',
      imgPath: 'marionnaud.png'
    });
    this.retailerImg.set('Nocibé', {
      name: 'Nocibé',
      imgPath: 'nocibe.png'
    });
    this.retailerImg.set('Sephora', {
      name: 'Sephora',
      imgPath: 'sephora.png'
    });
    this.retailerImg.set('Decathlon', {
      name: 'Decathlon',
      imgPath: 'decathlon.png'
    });
  }

  getImgPath(retailerName: string): string {
    const retailerItem = this.retailerImg.get(retailerName);
    return retailerItem ? retailerItem.imgPath : 'carrefour.png';
  }
}
