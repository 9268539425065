import { Pipe, PipeTransform } from '@angular/core';
import { RetailerImgService } from './../services/retailer-img.service';

@Pipe({
  name: 'retailerImg'
})
export class RetailerImgPipe implements PipeTransform {

  constructor(private retailerImgService: RetailerImgService){}

  transform(value: string, ...args: unknown[]): unknown {
    const retailerIco = this.retailerImgService.getImgPath(value);
    const path = './assets/retailer-icons/' + retailerIco;
    return path;
  }

}
