import { Pipe, PipeTransform } from '@angular/core';
import { FidelityRetailer } from '../types/offer.type';

@Pipe({
  name: 'loyaltyFilterPipe'
})
export class LoyaltyFilterPipe implements PipeTransform {

  transform(loyaltyList: FidelityRetailer[] | null , search : string): FidelityRetailer[] | null {
    if (loyaltyList) return loyaltyList.filter(fidelityList => fidelityList.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
    else return null
  }

}
