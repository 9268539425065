import { AppTokenModule } from './app-token.module';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
//translate
import { AppTranslateModule } from './app-translate.module';
//routing
import { AppRoutingModule } from './app-routing.module';
import { DatePipe } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { LayoutsModule } from './layouts/layouts.module';
import { WebAppComponent } from './web-app/web-app.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';



registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    WebAppComponent
  ],
  imports: [
    HttpClientModule,
    AppRoutingModule,
    AppTranslateModule,
    BrowserAnimationsModule,
    CommonModule,
    AppTokenModule,
    LayoutsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    BrowserModule
  ],
  providers: [
    DatePipe,
    { provide: LOCALE_ID, useValue: "fr" }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
