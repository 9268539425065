<div class="d-flex flex-justify-content food-header">
    <a href="https://www.750g.com" rel="noopener">Retourner sur 750g</a>
</div>

<!-- <div class="food-header-container">
    <div class="food-header-logo food-header-u-color-primary">
        <svg class="svg-icon" aria-label="Logo de 750 grammes" class="food-header-u-icon food-header-u-icon-logo" role="img" viewBox="0 0 603 283.52">
        <path fill="#08be93" d="M76.14 230.72H31.59L79.3 51.1H0V8.05h135.29L76.14 230.72zM161.41 173.5a37.32 37.32 0 10-1.22-30.57l-40.72-13.75L152.75 8.05H259.6v43.06h-74l-7.5 27.13a80.38 80.38 0 11-60 102.6zM432.83 39.23C418.32 13.67 395.43.49 364.75 0h-.17c-28.26 0-49.87 10.65-64.25 31.65-13.68 20-20.33 48.44-20.33 87s6.65 67 20.33 87c14.38 21 36 31.64 64.25 31.64s49.86-10.64 64.23-31.64c13.69-20 20.34-48.45 20.34-87 0-33.34-5.49-60.05-16.32-79.42zm-28.16 79.39a230 230 0 01-1.86 28.87v.18c-4.58 40.9-24 46.17-38.34 46.52-14 0-23.29-5.07-29.33-15.86-7.06-12.59-10.64-32.68-10.64-59.71s3.58-47.12 10.63-59.71c6.07-10.82 15.43-15.86 29.47-15.86S388 48.09 394 58.91c7.09 12.6 10.67 32.69 10.67 59.71zM565.69 78.72v8.73c-10.33-7.21-22.88-10.86-37.35-10.86-22.53 0-40.89 7.56-54.59 22.46-13.15 14.34-19.81 32.4-19.81 53.67s6.66 39.32 19.81 53.66c13.69 14.9 31.56 22.46 53.13 22.46 15.09 0 28.11-3.75 38.75-11.14-.41 10-3 17.48-7.82 22.38-5.17 5.3-14.33 8-27.22 8-24.26 0-29.65-11.08-31-17.35l-38.51 6.52A61.32 61.32 0 00486 271.39c11.83 8 28 12.13 48 12.13 20.57 0 37.33-5.81 49.82-17.29 12.7-11.67 19.14-28.51 19.14-50.07V78.72zM557 123.43c6.57 7.63 9.76 17.21 9.76 29.29s-3.19 21.64-9.71 29.22a36.17 36.17 0 01-11.72 8.38 38.08 38.08 0 01-15.79 3.07c-11.86 0-21-3.75-28-11.44s-10.25-17.36-10.25-29.23 3.35-21.44 10.24-29.23 16.18-11.45 28-11.45C541 112 550 115.78 557 123.43z"></path>
        </svg>
    </div>
    <div class="food-header-logo food-header-u-color-primary">
        <a href="https://www.750g.com" class="food-header-button" rel="noopener">Retourner sur 750g</a>
    </div>
</div> -->