import { UserToken } from '../../../shared/types/user.type';
import { PotDetail } from '../../../shared/types/pot.type';
import { Injectable } from '@angular/core';
import { action, runInAction } from 'mobx';
import { observable } from 'mobx';
import { DomainStore } from 'src/app/core/state-manager/abstract-store';
import { BehaviorSubject } from 'rxjs';
import { PotRestService } from '../services/pot-rest.service';




@Injectable({
  providedIn: 'root'
})
export class UserStoreService extends DomainStore{

  public userServiceToken: BehaviorSubject<any> = new BehaviorSubject(null);
  // public potDetail: BehaviorSubject<any> = new BehaviorSubject(null);
  // public isPotDetailLoading: BehaviorSubject<any> = new BehaviorSubject(false);

  /**
   * @constructor
   */
  constructor() {
    super();
  }

  /**
  * Update the user Token in local storage
  * @param userToken userToken from user service
  */
  @action
  public updateUserToken(userToken: UserToken) {
    this.userServiceToken.next(userToken);
  }

  /**
   * will clean all state (ex user switch)
   */
  clean(): void {
    this.userServiceToken.next(undefined);
  }

}
