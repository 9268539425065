<footer class="py-0 mt-0">
  <div class="d-flex flex-column footer-container">
    <div class="d-flex link-container">
      <div class="link-block d-flex">
        <ul class="d-flex flex-column p-10">
          <!-- <li *ngIf="title">{{  title }}</li> -->
          <li>
            <a href="https://goldcircle.io/mentions-legales/" target="_blank">{{ "footer.mentions-legales" | translate }}</a>
          </li>
          <li>
            <a href="https://goldcircle.io/cgu/" target="_blank">{{ "footer.cgu" | translate }}</a>
          </li>
          <li>
            <a href="https://goldcircle.io/politique-de-confidentialite/" target="_blank">
              {{ "footer.personal-data" | translate }}
            </a>
          </li>
        </ul>
      </div>
      <div class="link-block d-flex">
        <ul class="d-flex flex-column p-10">
          <li>
            <a href="https://goldcircle.io/faq/" target="_blank">{{ "footer.faq" | translate }}</a>
          </li>
          <!-- <li>
            <a href="https://goldcircle.io/#ccm" target="_blank" >{{ "footer.how-it-work.cashback" | translate }}</a>
          </li> -->
          <!-- <li>
            <a href="https://goldcircle.io/#ccm" target="_blank" >{{ "footer.how-it-work.coupon" |translate }}</a>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="rights-container">{{ "footer.rights" | translate }}</div>
  </div>
  <!-- footer -->
  <!-- MenLife -->
  <div>
    <iframe src="https://life.mensup.fr/gc-footer.html" width="100%" height="200px" title="footer men life"></iframe>
  </div>
  <!-- Les Numeriques -->
  <!-- <app-footer-lesnum/> -->
  <!-- 750g -->
  <!-- <app-footer-750g/> -->
  <!-- footer end -->
</footer>