import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

// ??
declare var process: any;

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  constructor() {}

  /**
   * Get variables values in environments files
   * @param key variable name in environment file
   * @returns variable value
   */
  private getEnvironmentVars(key: string): string {
    return typeof process !== 'undefined' && process && process.env
    ? process.env[key] || environment[key]
    : environment[key];
  }

  /**  SSO params */

  /**
   * @returns API Base URL
   */
  get APIBaseUrl(): string {
    return this.getEnvironmentVars('apiBaseUrl');
  }

  /**
   * @returns redirect uri - SSO
   */
  get ssoRedirectURI() : string  {
    return this.getEnvironmentVars('redirectURI');
  }

  /**
   * @returns referer uri - SSO
   */
  get ssoRefererURI() : string  {
    return this.getEnvironmentVars('refererURI');
  }

  /**
   * @returns error uri - SSO
   */
  get ssoErrorURI() : string  {
    return this.getEnvironmentVars('errorURI');
  }

  /**
  * @returns qiota uri - SSO
  */
  get ssoQiota() : string  {
   return this.getEnvironmentVars('qiota');
 }

  /**
  * @returns client_secret - SSO
  */
   get ssoClientSecret() : string  {
    return this.getEnvironmentVars('clientSecret');
  }

  /**
  * @returns client_ID - SSO
  */
     get ssoClientID() : string  {
      return this.getEnvironmentVars('clientID');
    }


  /**
  * @returns client_ID - SSO
  */
  get mediaOrigin() : string  {
    return this.getEnvironmentVars('mediaOrigin');
  }

  /**
   * @returns referer - SSO
   */
  get referer(): string {
    return this.getEnvironmentVars('referer')
  }

  /**
   * @returns Powens Endpoint
   */
  get budgetInsightEndpoint(): string {
    return this.getEnvironmentVars('budgetInsightEndpoint')
  }

  /**
   *
   * @returns production false | true
   */
  get production(): string {
    return this.getEnvironmentVars('production');
  }

}
